@font-face {
  font-family: "Manrope-Bold";
  src: url("../fonts/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

@font-face {
  font-family: "Manrope-ExtraBold";
  src: url("../fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.text-theme-white {
  color: $White-Color;
}
@font-face {
  font-family: "Manrope-ExtraLight";
  src: url("../fonts/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Light";
  src: url("../fonts/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Medium";
  src: url("../fonts/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Regular";
  src: url("../fonts/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: url("../fonts/Manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  word-break: auto-phrase;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #f3f3f9;
  scroll-behavior: smooth;
  font-family: "Manrope", sans-serif;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}
.mt-1_7 {
  margin-top: 1.7rem !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $theme-blue;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $theme-blue;
}

.hidden {
  display: none;
}
.f-l {
  font-size: 12px;
}

.f-ph {
  font-size: 10px;
}

.f-6 {
  font-size: 6px;
}

.f-8 {
  font-size: 8px;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-15 {
  font-size: 15px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-30 {
  font-size: 30px;
}

.f-36 {
  font-size: 36px;
}
.f-40 {
  font-size: 40px;
}
.f-50 {
  font-size: 50px;
}

.f-60 {
  font-size: 60px;
}

.f-reg {
  font-family: "Manrope-Regular";
}

.f-sem-bol {
  font-family: "Manrope-SemiBold";
}

.f-med {
  font-family: "Manrope-Medium";
}

.f-lig {
  font-family: "Manrope-Light";
}

.f-ex-lig {
  font-family: "Manrope-ExtraLight";
}

.f-ex-bol {
  font-family: "Manrope-ExtraBold";
}

.f-bol {
  font-family: "Manrope-Bold";
}

.mr-20 {
  margin-right: 20px;
}
.w-46 {
  width: 46% !important;
}
.w-48 {
  width: 48% !important;
}

.w-50 {
  width: 50%;
}
.w-10 {
  width: 10;
}
.w-2 {
  width: 2.5%;
}
.w-5 {
  width: 5%;
}
.w-6 {
  width: 6.5%;
}
.w-7 {
  width: 7.5%;
}
.w-9 {
  width: 9%;
}
.w-18 {
  width: 18%;
}
.w-10 {
  width: 10%;
}
.w-12 {
  width: 12%;
}
.w-8 {
  width: 8%;
}
.w-11 {
  width: 11%;
}
.w-13 {
  width: 13%;
}
.w-14 {
  width: 14%;
}
.w-15 {
  width: 15%;
}
.w-40 {
  width: 40%;
}
.w-16 {
  width: 16%;
}
.w-17 {
  width: 17%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-60 {
  width: 60%;
}
.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33%;
}
.w-35 {
  width: 35%;
}
.w-48 {
  width: 48%;
}
.w-125 {
  width: 12.5%;
}
.w-175 {
  width: 17.5%;
}
.w-175px {
  width: 175px;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-65 {
  width: 65%;
}
.h-100 {
  height: 100%;
}

.opacity-100 {
  opacity: 100;
}
.z-index-3 {
  z-index: 3;
}
.f-right {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.refresh-btn {
  height: 35px;
  width: 35px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.cursor-na {
  cursor: not-allowed !important;
}

.no-wrap {
  white-space: nowrap;
}

.word-break {
  word-break: break-word;
}
/* Hide calendar icon in Chrome, Safari, Edge, and Opera */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Hide calendar icon in Firefox */
input[type="date"] {
  -moz-appearance: textfield;
}

/* Hide calendar icon in Internet Explorer and old Edge versions */
input[type="date"]::-ms-clear {
  display: none;
}

input[type="date"]::-ms-expand {
  display: none;
}

.text-theme-black {
  color: $theme-black;
}
.tabel-wrapper {
  height: 340px !important;
}
.text-theme-blue {
  color: $theme-blue;
}

.text-theme-gray {
  color: $theme-gray;
}

.text-theme-dark-gray {
  color: #747474;
}

.text-theme-red {
  color: #f2464b !important;
}

.info-feedback {
  border-style: none;
  background-color: $theme-blue;
  color: $White-Color;
  padding: 0.6em 2em;
  border-radius: 0.5em;
  // margin-right: 10px;
  right: 7%;

  img {
    height: 0.8em;
    width: auto;
    margin-left: 5px;
  }
}

.social-sign-in-btn {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 0 30px;
  border: 1px solid $theme-border;
  font-family: "Manrope-Bold";
  font-size: 14px;
  line-height: 21px;
  color: $theme-black;
  background-color: $White-Color;

  @media screen and (max-width: 769px) {
    font-size: 12px;
    width: 100%;
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.divider-container {
  padding: 1em;

  .divider {
    display: inline-block;
    height: 1px;
    width: 80%;
    background-color: $theme-border;
  }
}

.pl-30 {
  padding-left: 30px;
}

.content-main {
  transition: 0.2s all linear;
  background-color: $theme-mercury;
  min-height: 100%;
  height: 100%;

  @media screen and (max-width: 992px) {
    padding-left: 0px;
  }
}

// button {
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
// }

* {
  transition: 0.2s all linear;
}

.lrf-banner-img {
  background-image: url("../images/lrf-banner.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #133f7d;
}

.myProfileDivider {
  width: 100%;
  height: 1px;
  background-color: $theme-blue;
  margin-bottom: 1em;

  @media screen and (min-width: 993px) {
    display: none;
  }
}

.goBackBadge {
  color: $theme-gray;
  cursor: pointer;

  &:hover {
    color: $theme-blue;
  }

  @media screen and (min-width: 993px) {
    display: none;
  }
}

.smallBtn {
  max-width: 40%;
  min-width: 40%;
}

.icon {
  margin: 0 0.4em 0 0;
}

.page-shrink {
  width: 80%;
}

.fontThemeColor {
  color: $theme-blue !important;
}

.fontGray {
  color: #83879e !important;
}

.inlineTitle {
  margin-right: 0.5em;
}

.globalSubtitle {
  color: $theme-subtitle-color;
}

.my-calender {
  color: $theme-blue;
}

.gray {
  color: $theme-placeholder;
}

.sectionTitle {
  color: $theme-blue;
  font-size: 22px;
}

.flexDontGrow {
  flex-grow: 0 !important;
}

.padding-1 {
  padding: 1em !important;
}

// shadows
.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

// .shadow-1:hover {
//   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
// }

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

// start of confirm delete popup //
.delete-popup {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  display: flex;
  .confirm-delete-box {
    background-color: $White-Color;
    width: 400px;
    padding: 20px;
    @media screen and (max-width: 992px) {
      width: 70%;
    }
    @media screen and (max-width: 580px) {
      width: 90%;
    }
    .accept_icon {
      width: 62px;
      height: auto;
    }
    .text_container {
      text-align: center;
      .title {
        font-size: 16px;
      }
      .text {
        font-size: 13px;
      }
    }
    .delete-box-logo {
      width: 5em;
    }
    .delete-box-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-style: none;
      height: 2.4em;
      width: 8em;
      color: white;
    }

    .confirm-red-btn {
      background-color: #d92d20 !important;
    }
    .confirm-grey-btn {
      background-color: #626262;
    }
    .confirm-green-btn {
      background-color: #039855;
    }
    .db-delete-btn {
      color: white;
      background-color: #e96f68;
    }
    .btn-green {
      background-color: #3a992b;
      color: white;
    }
    .db-cancel-btn {
      color: $theme-placeholder;
      background-color: $theme-light-blue;
    }
    .cancel-outline-btn {
      border: 1px solid #d0d5dd;
      color: black;
      background-color: transparent;
    }
  }
}
//end of confirm delete popup//

//start of main layout css//
.main-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 992px) {
    display: inherit;
    position: relative;
  }
}

.mainSidebar {
  height: 100%;
  background-color: $theme-blue;
  display: flex;
  @media screen and (max-width: 992px) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300px;
    z-index: 1000;
  }
}

.showSidebar {
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
}
.hideSidebar {
  // width: 60px;
  .sidebarContentContainer {
    padding: 20px 0 0 0;
  }
  @media screen and (max-width: 992px) {
    width: 0;
  }
}

.sidebar-close-btn {
  position: absolute;
  right: -8%;
  top: 10%;
  img {
    width: 100%;
    height: 100%;
  }
}

.headerPageContainer {
  height: 100%;
  background-color: $Back-ground;
  display: flex;
  flex-direction: column;
  .footer-container {
    width: 100%;
    min-height: 60px;
    margin-bottom: 30px;
  }
}
.headerPageContainer.fullWidth {
  width: 100%;
}
.fullWidth {
  // width: calc(100% - 60px);
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.semiWidth {
  width: calc(100% - 240px);
  margin-left: 240px;
}

.mainHeader {
  background-color: $White-Color;
  height: 50px;
  width: 100%;
  padding: 0 25px;
  position: fixed;
  top: 0;
  z-index: 2;
}
.type_select_box .select__control {
  height: 2.98em;
  min-height: 100%;
  white-space: nowrap;
}
.type_select_box .select__value-container {
  padding: 2px 8px !important;
}
.pageContainer {
  padding-top: 50px;
  width: 100%;
  background-color: #f3f3f9;
  // height: 100%;
  @media screen and (min-width: 1000px) {
    // overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-blue;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-blue;
    }
  }
}

.page-container {
  width: 100%;
  height: 100%;
  padding: 18px 28px;
  min-height: calc(100vh - 100px);
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
  @media screen and (max-width: 992px) {
    padding: 10px;
  }
  @media screen and (min-width: 1000px) {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-blue;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-blue;
    }
  }
}

//end of main layout css//

.input-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//subcription-card//
.subscription-card {
  display: inline-block;
  margin-bottom: 40px;
  width: auto;
  .sub-card-head {
    display: flex;
    .sub-card-label {
      padding: 5px 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #12b6bc;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .sub-card-body {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    .sub-card-body-part-1 {
      display: flex;
      align-items: center;
      min-width: 300px;
      .sub-card-logo {
        width: 100px;
        height: auto;
        margin-right: 20px;
      }
      .sub-card-plan-details {
        .sub-card-plan-name {
          color: #133f7d;
        }
        .sub-card-plan-status {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2.5px 10px;
          border-radius: 5px;
          .sub-card-status-dot {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        .status-ongoing {
          width: 83px;
          border: 1px solid #0086d2;
          color: #0086d2;
          .sub-card-status-dot {
            background-color: #0086d2;
          }
        }
        .status-expired {
          width: 77px;
          background-color: #fef2f2;
          color: #f2464b;
          .sub-card-status-dot {
            background-color: #f2464b;
          }
        }
        .status-canceled {
          width: 91px;
          background-color: #6262621a;
          color: #626262;
          .sub-card-status-dot {
            background-color: #626262;
          }
        }
      }
    }
    .sub-card-divider {
      background-color: #d5d8f5;
      width: 1.5px;
      margin: 0 60px 0 10px;
    }
    .sub-card-body-part-2 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .time-range-container {
        position: relative;
        margin-right: 60px;
        height: 70px;
        .time-range-img {
          width: 80px;
          height: auto;
        }
        .time-range-start,
        .time-range-end {
          position: absolute;
          width: 80px;
          text-align: center;
          top: 25px;
        }
        .time-range-start {
          left: -45px;
        }
        .time-range-end {
          right: -45px;
        }
      }
      .sub-card-btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .sub-card-btn {
          font-size: 12px;
          border: none;
          padding: 5px 10px;
          margin: 10px 0;
          // max-width: 100px;
          width: 100%;
        }
        .sub-card-btn-1 {
          background-color: #d0f0f2;
          color: #12b6bc;
        }
        .sub-card-btn-2 {
          background-color: rgba(19, 63, 125, 0.1);
          color: #133f7d;
        }
      }
    }
  }
}
//subcription-card//

.t-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.t-capital {
  text-transform: capitalize;
}
.z-index-9 {
  z-index: 9;
}

.f-white {
  color: $White-Color;
  text-decoration: none;
}

.popular-plans {
  background-color: $theme-light-red;
  color: white;
}

.text-transform {
  text-transform: capitalize;
}

.evp-sub-div .evp-m-sub {
  font-size: 12px;
  color: #8780ab;
}

.evp-sub-div .evp-sub {
  font-size: 16px;
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

//bulkupload popup//
.bulk-upload-popup {
  width: 300px;
  height: auto;
  background-color: $Back-ground;

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 5px;
    width: 5px;
  }

  a {
    text-decoration: none;
    margin-left: 10px;
  }
}
//bulkupload popup//

.text-align-center {
  text-align: center;
}
.delete-addons img {
  width: 12px;
}
.delete-addons {
  border: none;
}
.ql-container {
  height: 300px !important;
}
.padding-lr {
  padding: 0px 1em;
  color: #133f7d;
  height: 10%;
}
.add_role_note {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  line-height: 18px;
  margin-top: 25px;
}
.blue_text_color {
  color: #1c4784;
}
.add_edit_btn_container {
  gap: 20px;
}
.add_edit_btn_container .common-btn.cancel_btn {
  border: 1px solid #1c4784;
  background: #fff;
  color: #1c4784;
}
.add_edit_btn_container .common-btn {
  max-width: 200px;
  width: 100%;
}
.create-jd-btn {
  background: #12b6bc !important;
  // padding: 15px 24px;
  color: #ffffff;
}
.even-row {
  background-color: #f4f4f4;
}
.odd-row {
  background-color: white;
}
.info-tabel {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  .profile-pic-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 1.5px solid #133f7d;
    .profile-pic {
      height: 100%;
      width: auto;
    }
  }
  .action-btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    button {
      border: none;
      // padding: 5px 10px;
      padding: 0.625rem 1.25rem;
      border-radius: 5px;
      color: #ffffff;
      background-color: red;
    }
    .abc-type-1 {
      background-color: #00c851;
    }
    .abc-type-2 {
      background-color: #12b6bc;
    }
    .abc-type-3 {
      background-color: #f2464b;
    }
    .abc-type-4 {
      background-color: #f49454;
    }
    .abc-type-5 {
      background-color: #e8aa4e;
    }
  }
}
.info-tabel-header {
  td {
    text-align: center !important;
    color: #133f7d;
    padding: 10px !important;
    font-size: 14px;
  }
}
.logs-header {
  background-color: #133f7d;
  color: #ffffff;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #ffffff;
}
.logs-tabel {
  background-color: #ffffff;
}
.create-jd-btn {
  display: inline-block;
  background: #11b2b8;
  color: #fff;
  height: 47.8px;
  border: none;
  // float: right;
  // margin-top: -47.8px;
  // width: 33%;
  white-space: nowrap;
}
.skip-btn {
  background-color: initial;
  border: 1.4px solid #123a75;
  color: #123a75;
  padding: 7px;
}
.skip-btn .skip-btn-img {
  height: 10px;
  margin-left: 5px;
  width: auto;
}
.sq-heading {
  color: #0d0df5;
}
.sq-add {
  color: #0c0c0d;
}
.sq-generate-btn,
.sq-generate-btn1 {
  border: none;
  border-radius: 5px;
  color: #fff;
  outline: none;
  padding: 5px 10px;
}
.sq-generate-btn1 {
  background-color: #9b9b9b;
}

.create-jd-btn-2 {
  display: inline-block;
  background: #9b9b9b;
  color: #fff;
  height: 47.8px;
  border: none;
  white-space: nowrap;
}

.jd-to-cv-match-btn {
  display: inline-block;
  width: 200px;
  background: #11b2b8;
  color: #fff;
  height: 40px;
  border: none;
  margin-top: 30px;
}
.save-field {
  background: #11b2b8;
  color: #fff;
  padding: 0px 20px;
  height: 46px;
  margin-top: 28px;
  border: none;
}

.jd-to-cv-match-btn-2 {
  display: inline-block;
  width: 200px;
  background: #626262;
  color: #fff;
  height: 40px;
  border: none;
  margin-top: 30px;
}

.create-jd-div {
  width: 65% !important;
}

.jDDataValue-container {
  background-color: #ffffff;
  padding: 10px;
  .jd-title-container {
    width: 80%;
    .jd-title {
      font-family: "Manrope", sans-serif;
      font-weight: 600;
      color: "#171717";
    }
    .jd-company-name {
      font-family: "Manrope", sans-serif;
      font-weight: 400;
      margin-bottom: 16px;
      color: "#191919";
    }
    .jd-title-divider {
      height: 20px;
      width: 1.2px;
      background-color: black;
      margin: 0 5px;
    }
  }
  .jd-logo {
    width: 40px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.jDDataValue-div {
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  font-family: "Manrope-Regular";
  margin: 0px !important;
  // background: #fff;
  resize: none;
  overflow-y: auto;
  border: none;
  color: #171717;
  word-break: keep-all;
  &::-webkit-scrollbar {
    display: none;
  }
  &:focus {
    outline: none;
  }
}
.questions-wrapper {
  @media screen and (max-width: 900px) {
    width: 100%;
    position: relative;
    z-index: initial;
    top: 0;
    right: 0;
  }
  z-index: 3;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 33%;
  height: calc(100% - 60px);
  background-color: #f0f2f9;
  .screen-qs-wrapper {
    padding: 30px 30px 10px;
    height: calc(100% - 82px);
  }
  .screen-qs-container {
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  .screen-qs-container2 {
    background-color: #fff;
    height: calc(100% - 80px);
    overflow-y: auto;
  }

  .btn-close {
    width: 5px;
    height: 5px;
    top: 2.5%;
    right: 1.5%;
  }

  .custom-qs {
    padding: 1.2em;
    outline: none;
    width: 95%;
  }

  .custom-qs-box {
    background-color: $White-Color;
    border-radius: 0.3em;
    overflow: hidden;
  }
}

.feedback-btn {
  border-style: none;
  background-color: $theme-blue;
  color: $White-Color;
  padding: 5px 10px;
  border-radius: 0.5em;
  margin-right: 10px;
  height: 2.4em;
  text-decoration: none;
  &:hover {
    color: $White-Color;
  }
  img {
    height: 1em;
    width: auto;
    margin-left: 10px;
  }
}

.download-btn {
  color: #133f7d;
  cursor: pointer;
  border: 1px solid #133f7d;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 700;
  border-radius: 0.5em;
  background-color: #ffffff;
  .download-btn-img {
    height: 11px;
    width: auto;
    margin-left: 10px;
  }
}
.light-color {
  color: #626262;
  opacity: 0.5;
}

.popup-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .faq-popUp-width {
    width: 400px;
  }
  .edit-pay-popup-box {
    height: auto;
    width: 300px;
    background-color: $Back-ground;
    border-radius: 1em;
    padding: 2em;
    .title {
      line-height: 1;
      h3 {
        margin: 0;
      }
      .close-btn {
        img {
          height: 1em;
        }
      }
    }
    .input-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 400px;
      overflow-y: auto;
      padding-right: 10px;
      .input-field {
        margin: 1% 0;
      }
      .textarea {
        width: 100%;
      }
      .btn-container {
        margin-top: 2em;
      }
    }
  }

  .popup-box {
    height: auto;
    width: 420px;
    background-color: $Back-ground;
    border-radius: 1em;
    padding: 2em;

    .title {
      line-height: 1;
      h3 {
        margin: 0;
      }
      .close-btn {
        img {
          height: 1em;
        }
      }
    }
    .input-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 400px;
      overflow-y: auto;
      padding-right: 10px;
      .input-field {
        margin: 1% 0;
      }
      .textarea {
        width: 100%;
      }
      .btn-container {
        margin-top: 2em;
      }
    }
  }
}
.popUp_btn {
  height: 44px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}
.confirm-red-btn {
  background-color: #f2464b;
  color: #ffffff;
}
.confirm-grey-btn {
  background-color: #626262;
}
.enable-two-factor-btn {
  background-color: #12b6bc;
}
.cancel-two-factor-btn {
  color: #133f7d;
  border: 1px solid #133f7d;
  padding: 5px 10px;
  background-color: #f3f3f9;
}
.confirm-white-btn {
  border-radius: 8px !important;
  color: #171717 !important;
  border: 1px solid #344054 !important;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.confirm-green-btn {
  background-color: #039855;
}
.db-delete-btn {
  color: white;
  background-color: #e96f68;
}
.btn-green {
  background-color: #3a992b;
  color: white;
}

.very-poor-color-card {
  background-color: #f2464b;
}
.poor-color-card {
  background-color: #ff9f43;
}
.average-color-card {
  background-color: #ffca4c;
}
.good-color-card {
  background-color: #83d17a;
}
.very-good-color-card {
  background-color: #00c851;
}
.excellent-color-card {
  background-color: #018d3a;
}
.scheduled-color-card {
  background-color: #ff822e;
}
.cancelled-color-card {
  background-color: #f2464b;
}
.pending-color-card {
  background-color: #9b9b9b;
}
.completed-color-card {
  background-color: #18ac00;
}
.accepted-color-card {
  background-color: #133f7d;
}

.interview-status {
  padding: 5px 10px;
  border-radius: 5px;
  height: 3em;
  display: flex;
  align-items: center;
  margin-left: 10px;
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.very-poor-color {
  color: #f2464b;
  background-color: rgba(254, 242, 242, 1);
  .status-dot {
    background-color: #f2464b;
  }
}

.new-status-color {
  color: #12b6bc;
  background-color: #bce7e9;
  .status-dot {
    background-color: #12b6bc;
  }
}
.poor-color {
  color: #ff9f43;
  background-color: #faecde;
  .status-dot {
    background-color: #ff9f43;
  }
}
.average-color {
  color: #ffca4c;
  background-color: #f6f1e5;
  .status-dot {
    background-color: #ffca4c;
  }
}
.good-color {
  color: #83d17a;
  background-color: #ebf5eb;
  .status-dot {
    background-color: #83d17a;
  }
}
.very-good-color {
  color: #00c851;
  background-color: #ebf5eb;
  .status-dot {
    background-color: #00c851;
  }
}
.excellent-color {
  color: #018d3a;
  background-color: #ebf5eb;
  .status-dot {
    background-color: #018d3a;
  }
}

.shortlisted-color {
  color: #18ac00;
  background-color: #ebf5eb;
  .status-dot {
    background-color: #18ac00;
  }
}
.scheduled-color {
  color: #ff822e;
  background-color: #fbf3ef;
  .status-dot {
    background-color: #ff822e;
  }
}

.cancelled-color {
  color: #f2464b;
  background-color: rgba(254, 242, 242, 1);
  .status-dot {
    background-color: #f2464b;
  }
}
.pending-color {
  color: #626262;
  background-color: #f0f0f2;
  .status-dot {
    background-color: #626262;
  }
}
.completed-color {
  color: #18ac00;
  background-color: #ebf5eb;
  .status-dot {
    background-color: #18ac00;
  }
}
.header-content-img {
  width: 1em;
  margin-left: 5px;
  cursor: pointer;
}
.status-div {
  height: 25px;
}
.download-sample {
  text-decoration: none;
  color: #1919eb;
  font-weight: 600;
}

.jd-resume-btn {
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #133f7d;
  border: none;
  width: 80px;
}

.invite-btn-wrapper {
  position: relative;
  .invite-btn {
    border-radius: 0.5em;
    background-color: $theme-blue;
    padding: 6px 12px;
    cursor: pointer;
    color: $White-Color;
  }
  .send-invite-dropdown {
    position: absolute;
    right: 0px;
    background-color: $White-Color;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    .send-invite-option {
      width: 180px;
      padding: 5px;
      cursor: pointer;
      color: $theme-subuser;
      border-radius: 5px;
      margin: 10px;
      &:hover {
        background-color: $theme-light-blue;
        color: $theme-blue;
      }
      img {
        height: auto;
      }
      span {
        width: 160px;
        font-size: 16px;
      }
    }
  }
}

.invite-btn-wrapper-2 {
  position: relative;
  z-index: 1;
  margin-right: 16px;
  .invite-btn {
    // height: 2.4em;
    border-radius: 0.5em;
    background-color: $theme-blue;
    width: 80px;
    cursor: pointer;
    color: $White-Color;
  }

  .send-invite-dropdown {
    position: absolute;
    right: 0px;
    background-color: $White-Color;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    .send-invite-option {
      width: 180px;
      padding: 5px;
      cursor: pointer;
      color: $theme-subuser;
      border-radius: 5px;
      margin: 10px;

      &:hover {
        background-color: $theme-light-blue;
        color: $theme-blue;
      }

      img {
        height: auto;
      }

      span {
        width: 160px;
        font-size: 16px;
      }
    }
  }
}
.status-type-1 {
  background: rgba(19, 63, 125, 0.1);
  color: #133f7d;

  .dot-color {
    background-color: #133f7d;
  }
}

.status-type-2 {
  background: rgba(18, 182, 188, 0.1);
  color: #12b6bc;

  .dot-color {
    background-color: #12b6bc;
  }
}

.status-type-3 {
  background: #ebf5eb;
  color: #18ac00;

  .dot-color {
    background-color: #18ac00;
  }
}

.status-type-4 {
  background-color: #f0f0f2;
  color: #626262;

  .dot-color {
    background-color: #626262;
  }
}

.status-type-5 {
  background-color: #fef2f2;
  color: #f2464b;

  .dot-color {
    background-color: #f2464b;
  }
}

.status-type-6 {
  background-color: #fff6f0;
  color: #ff822e;

  .dot-color {
    background-color: #ff822e;
  }
}

.status-type-7 {
  background-color: #edf7fc;
  color: #0086d2;

  .dot-color {
    background-color: #0086d2;
  }
}

.status-sticker-type-1 {
  background-color: #133f7d;
}

.status-sticker-type-2 {
  background-color: #12b6bc;
}

.status-sticker-type-3 {
  background-color: #18ac00;
}

.status-sticker-type-4 {
  background-color: #626262;
}

.status-sticker-type-5 {
  background-color: #f2464b;
}

.status-sticker-type-6 {
  background-color: #ff822e;
}
.interview-status-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  .status-dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.invoice-display {
  position: absolute !important;
  left: -2000px !important;
}
.rounded-pill.f-bol.evp-btn1.common-btn.red_btn {
  background-color: rgba(242, 70, 75, 0.15);
  color: #f2464b;
}
.gap-5px {
  gap: 5px;
}
.breadcrum_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #667085;
}
.subscription-card .sub-card-head .sub-card-label.Subscribed {
  background-color: #133f7d;
}
.subscription-card .sub-card-head .sub-card-label.Expired {
  background-color: #f2464b;
}
.info-tabel td:first-of-type {
  white-space: nowrap;
}
.info-container.incExit_info_container .table-list {
  min-height: 90%;
}
.info-container.incExit_info_container .info-tabel .call_btn {
  width: auto;
  padding: 5px 10px;
  white-space: nowrap;
}
.pagination-text {
  color: #abadb7;
}
.pagination-divider {
  border-top: 1px solid #abadb7;
}
.position-table-container .table-list .info-tabel:nth-child(odd) {
  background-color: #f4f4f4;
}
.position-table-container .table-list .info-tabel:nth-child(even) {
  background-color: #fff;
}
.hw-24 {
  height: 24px;
  width: 24px;
}
.fw-700 {
  font-weight: 700;
}
.bulkFileIcon {
  right: 0;
}
.info-tabel .action-btn-container .add_question_btn {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.w-91 {
  width: 91%;
}
.w-915 {
  width: 91.5%;
}
@media (max-height: 750px) {
  .schedule_content_container {
    max-height: 450px;
    overflow: auto;
  }
  .question_content_container {
    max-height: 430px;
    overflow: auto;
  }
}

.invite-bt {
  background-color: #172b4d;
  color: #ffffff;
  width: 165px;
}
.bin_icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.logs_btn {
  background-color: #2dce89;
}
.headerPageContainer.semiWidth .mainHeader {
  width: calc(100% - 240px);
}
.allot-popup {
  height: 490px !important;
  overflow: auto !important;
}

.preview-btn {
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-left: 10px;
  border-style: none;
  border-radius: 50%;
  display: inline-table !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table_head {
  border-bottom: 1px solid;
}

th {
  font-weight: 700;
  font-size: 18px;
}

th,
td {
  padding: 18px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

// Reports css end
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 60%; /* Adjust as needed */
  vertical-align: middle; /* Optional: align text vertically */
  cursor: pointer;
}

.ellipsis-event-tracking {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.ellipsis-event-tracking:hover {
  user-select: all;
}
.support_assign_dropdown .select-dropdown {
  width: 100%;
}
.status-closed,
.status-cancelled,
.status-canceled,
.status-unattended {
  background-color: #fef2f2;
  color: #f2464b;

  .dot-color {
    background-color: #f2464b;
  }
}
.CanceledColor,
.UnattendedColor {
  color: #f2464b;
}

.status-recommended {
  background-color: rgba(235, 245, 235, 1);
  color: rgba(24, 172, 0, 1);

  .dot-color {
    background-color: rgba(24, 172, 0, 1);
  }
}
.RecommendedColor {
  color: rgba(24, 172, 0, 1);
}
.status-pending-card {
  background-color: #9b9b9b !important;
  color: #f0f0f2 !important;
}
.status-not-recommended-card {
  background-color: #8b0000;
  color: #f7eded;
}
.status-notrecommended {
  background-color: #f7eded;
  color: #8b0000;

  .dot-color {
    background-color: #8b0000;
  }
}
.Not.RecommendedColor {
  color: #8b0000;
}

.status-cautiouslyrecommended {
  background: #fdf9f3;
  color: #e8aa4e;

  .dot-color {
    background-color: #e8aa4e;
  }
}
.status-cautiouslyrecommended-card {
  background: #e8aa4e;
  color: #fdf9f3;
}
.Cautiously.RecommendedColor {
  color: #e8aa4e;
}

.status-pending,
.status-noshow,
.status-invited {
  background-color: #f0f0f2;
  color: #626262;

  .dot-color {
    background-color: #626262;
  }
}
.PendingColor {
  color: #626262;
}
.info-container .list .table-list .tl-row .list-id,
.info-container .list .table-list .tl-row .view-more {
  background-color: #123a75;
  border-radius: 0.4em;
  color: #fff;
  padding: 5px;
}
.question-set-div {
  z-index: 100001 !important;
}
.question-set-div {
  background: #f6f7fb;
  padding: 30px !important;
}
.question-set-info {
  border-radius: 2px;
  background: #fff;
  color: #626262;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  padding: 10px;
  margin: 10px;
}

.question-set-header {
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.not-available {
  color: #a9a9ab;
  font-family: Manrope-Regular;
  font-weight: 700;
  text-decoration: none;
  cursor: auto !important;
}
.status-unattended {
}

// .invoice-download-popup{
// background-color: $White-Color;
// z-index: 999;
// position: absolute;
// padding:10px;
// margin-top: 10px;
// border-radius: 10px;
// }

.invoice-download-popup {
  z-index: 999;
  position: absolute;
  width: 200px;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
}

.cancelReason {
  height: 100%;
  width: 25px;
  .cancelReasondiv {
    height: 100%;
    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.feedbackApprovedRemarks {
  background-color: #f70000;
  opacity: 0.7;
}
.feedbackReviewReason {
  background-color: #ffcb22;
  opacity: 0.7;
}
.cancel-reason-img {
  cursor: pointer;
}
.hide-btn {
  box-shadow: none !important;
  background-color: white !important;
}
.excel-grey-btn {
  background-color: #626262;
}
.rating-dropdown {
  display: inline-block;
  width: 120px;
}
.info-container code {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.39px;
  color: #626262;
}

.view-recording-btn {
  border-style: none;
  background-color: #e6e9f8;
  padding: 3px 8px;
  height: 25px;
  box-sizing: border-box;
  img {
    height: 12px;
    width: auto;
  }
}
.new-article-popup {
  width: 450px !important;
}
.new-meeting-popup {
  width: 350px !important;
}
.blurry-image-container {
  filter: blur(5px);
}

.qr-code {
  width: 120px;
  height: "auto";
}
.status-inprogress {
  background-color: #133f7d;
  color: #fff;
}
.status-inprogress .dot-color {
  color: #fff;
  background-color: #fff;
}
.feedback_threeDot.threedot-btn {
  width: 33px;
  height: 33px;
}

.blog-wrapper {
  display: flex;
  margin-top: 40px;
  .blog-wrapper-left {
    width: 100%;
    margin-right: 100px;
    .blog-title-wrapper {
      margin: 0 0 20px 0;
    }
    .blog-description-wrapper {
      margin: 0 0 20px 0;
      padding-right: 10px;
    }
  }
  .blog-wrapper-right {
    padding-right: 50px;
    padding-left: 50px;
  }
  .banner-image {
    display: flex;
    justify-content: center;
  }
  .banner-input {
    border-radius: 15px;
    height: 154px;
    width: 160px;
  }
}
.ql-editor,
.ql-container {
  max-height: 200px; /* Adjust the height as needed */
}
.ql-container.ql-snow {
  border: none !important;
  background: white;
  border-radius: 5px;
}
.ql-toolbar.ql-snow {
  border: none !important;
}
.blog-date-text {
  margin-top: 20px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 50%;
    padding: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    background-color: #f7f9fc;
    font-size: 12px;
  }

  .react-datepicker {
    font-size: 12px;
  }

  .react-datepicker__time-container .react-datepicker__time {
    background-color: #f7f9fc;
  }

  .react-datepicker__header {
    background-color: #f7f9fc;
  }

  .react-datepicker__day {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
  }
}
.footer-text {
  font-size: 12px;
}
.blog-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .btn-publish,
  .btn-draft {
    padding: 10px 20px;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .btn-publish {
    background-color: #003366; /* Dark blue */
    color: #fff;
    font-weight: bold;
  }

  .btn-publish:hover {
    background-color: #002244; /* Darker blue */
  }

  .btn-draft {
    background-color: #fff;
    color: #003366;
    border: 1px solid #003366;
  }

  .btn-draft:hover {
    background-color: #f0f0f0;
  }
}
.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  width: 50px;
  height: 50px;
}
.updated-image {
  width: 150px;
  height: auto;
}
.status-label.published {
  color: green;
  font-weight: bold;
}

.status-label.draft {
  color: gray;
  font-weight: bold;
}

.status-label.unpublished {
  color: red;
  font-weight: bold;
}
.menu-options {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

.menu-option {
  padding: 8px;
  cursor: pointer;
}

.menu-option:hover {
  background-color: #f0f0f0;
}

.cursor-default {
  cursor: default;
}
.panelist-Skills-container {
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.panelist-Skills {
  background-color: #12b6bc;
  color: white;
  width: 60px;
  height: auto;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
  margin: 0 1px;
  font-size: 10px;
  cursor: default;
}

.panelist-Skills:hover {
  user-select: all;
}
.mock-interview {
  @media screen and (max-width: 992px) {
    .row-container {
      min-width: 980px !important;
    }
  }
}

.file-input {
  border: 0.1px dashed $theme-blue;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
  cursor: pointer;
  .real-file-input {
    position: absolute;
    visibility: hidden;
  }
  .fake-file-input {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #ffffff;
    padding: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .fake-placeholder {
      color: #918ab2;
    }
  }
  .file {
    z-index: 1;
    height: 80%;
    right: 0;
    bottom: 12%;
  }
  .close-mark {
    top: 37.5%;
    right: 5%;
    height: 25%;
    cursor: pointer;
    z-index: 1;
  }
}
.accordion-head-main {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: black !important;
}

.accordion-head-sub {
  color: #374659;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.accordion-head-btn {
  padding: 7px 12px;
  color: #133f7d;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.accordion-head-btn.question {
  min-width: 130px;
}

.accordion-head-btn.marks {
  min-width: 105px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg); /* Rotates arrow to the right */
}

.accordion-button::after {
  transform: rotate(-90deg); /* Rotates arrow to the right */
}

.accordion-button {
  box-shadow: none !important; /* Removes highlight when selected */
  outline: none !important; /* Removes outline */
  background-color: transparent; /* Makes background transparent */
  color: #000; /* Keeps text color black */
}

.accordion-button:focus {
  box-shadow: none !important; /* Removes focus highlight */
}

.accordion-button {
  background-color: transparent !important; /* Ensures no hover effect */
}

.accordion-button.collapsed {
  color: #000; /* Keeps text color black */
}

.custom-accordion-item {
  background: transparent;
  border: none;
}

.custom-accordion-head {
  background: white;
}

.custom-accordion-body {
  margin-top: 5px;
  background: white;
  width: 95%;
}

.accordion-collapse {
  display: flex;
  justify-content: center;
}

.question-set-section-head {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.question-set-item {
  background-color: #f2f2f2;
  padding: 5px 0 5px 10px;
  width: 100%;
  min-height: 46px;
  margin-bottom: 0;
  text-wrap: auto;
  max-height: 250px;
}

.question_number {
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 19.12px;
  color: #000;
  margin-top: 5px;
}

.question_item {
  background-color: #f2f2f2;
  padding: 5px 0 5px 10px;
  width: 450px;
  min-height: 46px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  code {
      color: #000;
      font-family: 'Manrope';
      font-size: 12px;
      font-weight: 400;
  }
}

.questions-table {
  width: 96%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 40px;
}

.questions-table-set {
  width: 100% !important;
  margin-left: 0px !important;
}

.questions-table th,
.questions-table td {
  padding: 10px;
  border: none;
  text-align: center;
  font-size: 12px;
}

.questions-table th {
  color: #626262;
}

.questions-table th {
  background: #f2f2f2;
}
